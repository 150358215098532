/** @jsx jsx */
/** @jsxFrag */
import { jsx, css } from '@emotion/react';

import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import BaseURL from './BaseURL';
import { fetchUsers } from './actions';
import { InflatedGame } from '../game/Game';
import { useNavigate } from 'react-router-dom';
import PageHeaderView from './PageHeaderView';
import Session from './Session';
import LogoBanner from './LogoBanner';

type User = { name: string };
interface GameHistoryEntry {
  id: string;
  gameStartTimestamp: number;
  gameEndTimestamp: number;
  players: {
    userID: string;
  }[];
}
type Props = {
  dispatch: any;
  history: History;
  userByID: { [userID: string]: User };
  session?: Session;
};
function GameHistoryListPage(props: Props) {
  let { dispatch } = props;
  const [gameList, setGameList] = React.useState<GameHistoryEntry[] | null>(
    null,
  );
  const [showBots, setShowBots] = React.useState(false);
  const [showSessionUser, setShowSessionUser] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams();
    if (showBots) {
      params.set('includeBots', '1');
    }
    if (props.session && showSessionUser) {
      params.set('userID', props.session.getUserID());
    }
    fetch(BaseURL + '/api/game_history_list?' + params.toString())
      .then((response) => {
        if (!response.ok) {
          throw new Error('error fetching game history list: ' + response.text);
        }
        return response.json();
      })
      .then((game_list: InflatedGame[]) => {
        const userIDs = new Set<string>();
        for (const game of game_list) {
          for (const player of game.players) {
            userIDs.add(player.userID);
          }
        }
        dispatch(fetchUsers(Array.from(userIDs.values())));

        game_list.sort((a, b) => {
          return b.gameStartTimestamp! - a.gameStartTimestamp!;
        });

        return setGameList(game_list as any);
      });
  }, [showBots, showSessionUser]);

  const onClickEntry = useCallback((gameID) => {
    navigate('/game/' + gameID);
  }, []);
  if (!gameList) {
    return <div>Loading...</div>;
  }
  var userByID = props.userByID;
  var list = gameList.map((game) => {
    var date = new Date(game.gameStartTimestamp!);
    var player_names = game.players.map((p) => {
      var user = userByID[p.userID];
      return user?.name || '(unknown-user)';
    });
    return (
      <tr key={game.id} css={GameHistoryListStyles.item}>
        <td css={GameHistoryListStyles.cell}>
          {date.toLocaleDateString()} {date.toLocaleTimeString()}
        </td>
        <td css={GameHistoryListStyles.cell}>{player_names.join(', ')}</td>
        <td css={GameHistoryListStyles.cell}>
          {formatTime(game.gameEndTimestamp - game.gameStartTimestamp)}
        </td>
        <td css={GameHistoryListStyles.cell}>
          <button onClick={() => onClickEntry(game.id)}>View Game</button>
        </td>
      </tr>
    );

    function formatTime(time: number): string {
      const minutes = Math.floor(time / 60000);
      const seconds = Math.floor((time % 60000) / 1000);
      return `${minutes}m${seconds}s`;
    }
  });

  return (
    <>
      <PageHeaderView session={props.session} />
      <LogoBanner />
      <div css={GameHistoryListStyles.controls}>
        <label>Show bots: </label>
        <input
          type="checkbox"
          checked={showBots}
          onChange={(e) => setShowBots(e.target.checked)}
        />
        {props.session && (
          <>
            <label>My games</label>
            <input
              type="checkbox"
              checked={showSessionUser}
              onChange={(e) => setShowSessionUser(e.target.checked)}
            />
          </>
        )}
      </div>
      <div css={GameHistoryListStyles.container}>
        <table css={GameHistoryListStyles.table}>
          <tbody>{list}</tbody>
        </table>
      </div>
    </>
  );
}

const GameHistoryListStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  controls: css({}),
  table: css({}),
  item: css({
    backgroundColor: 'rgb(200, 200, 200)',
  }),
  cell: css({
    margin: 12,
    minWidth: 250,
    paddingTop: 4,
    paddingBottom: 4,
  }),
};

function select(state: any) {
  return {
    userByID: state.userByID.toJSON(),
    session: state.session,
  };
}

export default connect(select)(GameHistoryListPage);
