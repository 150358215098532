import * as _ from 'underscore';
import * as Utility from './Utility';
import { Bid, CardWithID, RerollSelection } from './Rules';

export type RenderedPlayer = ReturnType<Player['render']>;

export default class Player {
  private userID_: string;
  private botType_: string | null;

  cards: CardWithID[] = [];
  counters: Utility.CounterDelta;

  bid: Bid | null = null;
  selectedCard: CardWithID | null = null;
  rollSelection: RerollSelection | null = null;

  constructor(userID: string, botType?: string) {
    this.userID_ = userID;
    this.botType_ = botType || null;

    this.counters = Utility.makeCounterDelta();
  }

  getID(): string {
    return this.userID_;
  }
  get userID(): string {
    return this.userID_;
  }
  get botType(): string | null {
    return this.botType_;
  }
  getBotType(): string | null {
    return this.botType_;
  }
  get cardIDs(): string[] {
    return this.cards.map((card) => card.id);
  }
  get selectedCardID(): string | null {
    return this.selectedCard && this.selectedCard.id;
  }

  render() {
    return {
      userID: this.userID_,
      botType: this.botType_,
      cardIDs: _.map(this.cards, (card) => card.id),
      counters: this.counters,
      bid: this.bid as Bid | null,
      selectedCardID: this.selectedCard ? this.selectedCard.id : null,
    };
  }

  toJSON(): any {
    return {
      userID: this.userID_,
      botType: this.botType_,
      cardIDs: _.map(this.cards, (card) => card.id),
      counters: this.counters,
      bid: this.bid,
      selectedCardID: this.selectedCard ? this.selectedCard.id : null,
    };
  }

  static fromJSON(json: any, cardsByID: { [key: string]: CardWithID }): Player {
    let ret = new Player(json.userID, json.botType);
    ret.userID_ = json.userID;
    ret.botType_ = json.botType;

    ret.cards = json.cardIDs.map((id: string) => cardsByID[id]);

    ret.counters = json.counters;

    ret.bid = json.bid;
    ret.selectedCard = json.selectedCardID
      ? cardsByID[json.selectedCardID]
      : null;

    return ret;
  }
}
