import * as _ from 'underscore';
import invariant from 'invariant';

import Bot from './Bot';
import RandomCardBot from '../bots/RandomCardBot';
// import TutorialBot from '../bots/TutorialBot';
import Game from './Game';
import Tutorial from './Tutorial';
import BasicBot, { Preferences } from '../bots/BasicBot';
import { CardType } from './CardTypes';
import { Resource } from './Resources';
import { TributeLogic } from './TributeLogic';

export const PlayableBots = {
  basic_bot: {
    bot: new BasicBot(),
    name: 'Easy Bot',
  },
  random_card: {
    bot: new RandomCardBot(),
    name: 'Random Card',
  },
  basic_bot2: {
    bot: new BasicBot({
      card_type_preferences: {
        [CardType.Leader]: 1,
        [CardType.Basic]: 1,
        [CardType.Resource]: 1,
        [CardType.Conflict]: 1,
        [CardType.Prayer]: 1,
      } as Record<CardType, number>,
      card_output_values_by_age: {
        gold: [0.5, 0.5, 0.5],
        military: [0.5, 0.5, 0.25],
      } as Record<Resource, number[]>,
      chaos: 0.001,
      target_gold_output_by_age: [4, 7, 8],
      military_supremacy_target: 1,
      military_supremacy_preference: 0.5,
      card_flat_favor_preference_by_age: [1, 1.5, 2],
      target_card_type_by_age: {
        [CardType.Leader]: [1, 1, 1],
        [CardType.Basic]: [0, 0, 0],
        [CardType.Resource]: [2, 4, 5],
        [CardType.Conflict]: [2, 3, 5],
        [CardType.Prayer]: [1, 3, 5],
      },
      bid_nothing_probability_by_age: [0.3, 0.3, 0.3],
      bid_nothing_threshold_by_age: [5, 5, 5],
      card_cost_value_reduction_by_age: [0.01, 0.01, 0.01],
      conserve_military_factor_by_age: [0.005, 0.005, 0.005],
    } as Preferences),
    name: 'Easy Bot 2',
  },
  valid_move: {
    bot: new Bot(),
    name: 'Valid Move',
  },
};
const OtherBots = {
  // valid_move: {
  //   bot: new Bot(),
  //   name: 'Bot',
  // },
  // tutorial: {
  //   bot: new TutorialBot(Tutorial, new RandomCardBot()),
  //   name: 'Tutorial',
  // },
};
const AllBots = { ...PlayableBots, ...OtherBots };

// returns true if any bot actions were added
export function addBotActions(game: Game): boolean {
  return game.getPlayers().some((player) => {
    let bot_type = player.getBotType();
    if (!bot_type) {
      return false;
    }

    let bot = AllBots[bot_type as keyof typeof AllBots].bot;
    invariant(bot, 'bot type "%s" not found', bot_type);

    let user_id = player.getID();
    let renderedGame = game.render(user_id);
    const inflatedGame = Game.inflateGame(renderedGame);
    let action = bot.actionForGameState(inflatedGame, user_id);

    if (action) {
      game.addAction(user_id, action);
      return true;
    }
    return false;
  });
}
