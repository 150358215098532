import {
  getAdditionalCosts,
  getBaseCountersAbility,
  getDraftCardAbility,
  getEachTurnCardAbility,
  getGainOtherCardAbility,
  getGainSelfCardAbility,
} from './CardAbilities';
import {
  CardType,
  CardSubType,
  assertCardType,
  assertCardSubType,
} from './CardTypes';
import { GameOptions, GameOptionsKey } from './GameOptions';

export type CardDef = {
  module: string;
  name: string;
  type: CardType;
  subType: CardSubType;
  age: number;
  cost: number;
  favor: number;

  imageName?: string;

  baseEffect?: string;
  turnEffect?: string;
  gainSelfEffect?: string;
  gainOtherEffect?: string;
  draftOtherEffect?: string;
  additionalCosts?: string;
};
export type TributeDef = {
  module: string;
  name: string;
  favor: [number, number, number];
  value?: [number, number, number];
  text: string;

  function: string;
  params: string;

  image?: string;
};
export type ModuleDef = {
  key: string;
  name: string;
  description: string;
  disabled?: boolean;
  cardDefs: CardDef[];
  tributeCardDefs: TributeDef[];

  gameOptionsToAdd: GameOptionsKey[];

  // cards to remove?
};
export type GameDefinitions = {
  cardDefs: CardDef[];
  tributeCardDefs: TributeDef[];
  modules: ModuleDef[];
};

export function validateCard(card: CardDef): void {
  assertCardType(card.type);
  assertCardSubType(card.subType);
  if (card.age < 0 || card.age > 3) {
    throw new Error(`Invalid card age: ${card.age}`);
  }

  getDraftCardAbility(card);
  getBaseCountersAbility(card);
  getEachTurnCardAbility(card);
  getGainSelfCardAbility(card);
  getDraftCardAbility(card);
  getGainOtherCardAbility(card);
  getAdditionalCosts(card);
}

export function mergeModuleCards(
  cardDefinitions: CardDef[],
  tributeCardDefinitions: TributeDef[],
  modules: ModuleDef[],
): {
  cardDefinitions: CardDef[];
  tributeCardDefinitions: TributeDef[];
} {
  let nameToCardDef = new Map<string, CardDef>();
  let nameToTributeDef = new Map<string, TributeDef>();
  cardDefinitions.forEach((card_def) => {
    nameToCardDef.set(card_def.name, card_def);
  });
  tributeCardDefinitions.forEach((card_def) => {
    nameToTributeDef.set(card_def.name, card_def);
  });

  modules.forEach((module) => {
    module.cardDefs.forEach((card_def) => {
      nameToCardDef.set(card_def.name, card_def);
    });
    module.tributeCardDefs.forEach((tribute_def) => {
      nameToTributeDef.set(tribute_def.name, tribute_def);
    });
  });

  return {
    cardDefinitions: Array.from(nameToCardDef.values()),
    tributeCardDefinitions: Array.from(nameToTributeDef.values()),
  };
}
