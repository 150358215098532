import React, { useEffect, useReducer } from 'react';
import { formatDuration } from './ViewUtilities';

interface Props {
  splitStartTimestamp?: number;
  extraSplitTime?: number;
  extraPreSplitTime?: number;
  className?: string;
  style?: React.CSSProperties;
}

export default function GameTimeView(props: Props) {
  const [_, forceUpdate] = useReducer((x: number) => x + 1, 0);
  useEffect(() => {
    const intervalID = setInterval(() => {
      forceUpdate();
    }, 216);
    return () => clearInterval(intervalID);
  }, []);

  let splitTime = props.extraSplitTime || 0;
  if (props.splitStartTimestamp) {
    splitTime += Math.max(Date.now() - props.splitStartTimestamp, 0);
  }
  const extraTime = props.extraPreSplitTime
    ? props.extraPreSplitTime + splitTime
    : 0;
  const extraString = extraTime ? ' (' + formatDuration(extraTime) + ')' : '';
  return (
    <span className={props.className} style={props.style}>
      {formatDuration(splitTime) + extraString}
    </span>
  );
}
