/** @jsx jsx */
/** @jsxFrag */
import { jsx, css, SerializedStyles } from '@emotion/react';

import CardBackAge1 from '../assets/card_images/CardBackAge1.jpg';
import CardBackAge2 from '../assets/card_images/CardBackAge2.jpg';
import CardBackAge3 from '../assets/card_images/CardBackAge3.jpg';
import CardBackTribute from '../assets/card_images/CardBackTribute.jpg';
import BaseCardView from './BaseCardView';
import React from 'react';

export type CardBack = {
  type: CardBackType;
  id: string;
};
export type CardBackType =
  | 'age1'
  | 'age2'
  | 'age3'
  | 'tribute'
  | 'basic1'
  | 'basic2'
  | 'basic3';
export type CardBackEventFunc = (card: CardBack, event: MouseEvent) => void;

type CardBackProps = {
  card: CardBack;
  onCardEnter?: CardBackEventFunc;
  onCardLeave?: CardBackEventFunc;
  overlayStyle?: React.CSSProperties;
};
export const CardBackView = React.forwardRef<HTMLDivElement, CardBackProps>(
  (props, ref) => {
    const desc = TypeToCardBackDesc[props.card.type];
    return (
      <BaseCardView
        ref={ref}
        landscape={props.card.type === 'tribute'}
        onMouseEnter={(e: any) => props.onCardEnter?.(props.card, e)}
        onMouseLeave={(e: any) => props.onCardLeave?.(props.card, e)}
        cardStyle={{
          backgroundImage: `url(${desc.image})`,
          backgroundPositionX: 'center',
          backgroundPositionY: 'center',
          backgroundSize: '100% 100%',
          // border: 'solid black 6px',
          border: 'none',
          boxShadow: 'none',

          filter: desc.filter,
        }}
        bodyStyle={{ boxShadow: 'none' }}
        overlayStyle={props.overlayStyle}
        titleView={null}
      ></BaseCardView>
    );
  },
);

type CardBackDesc = {
  image: string;
  filter?: string;
};
function cbd(image: string, filter?: string): CardBackDesc {
  return {
    image,
    filter,
  };
}

const BasicFilter = 'brightness(0.7) grayscale(100%) sepia(100%)';
const TypeToCardBackDesc: Record<CardBackType, CardBackDesc> = {
  age1: cbd(CardBackAge1),
  age2: cbd(CardBackAge2),
  age3: cbd(CardBackAge3),
  tribute: cbd(CardBackTribute),
  basic1: cbd(CardBackAge1, BasicFilter),
  basic2: cbd(CardBackAge2, BasicFilter),
  basic3: cbd(CardBackAge3, BasicFilter),
};
