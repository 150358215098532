import invariant from 'invariant';

import * as CardAbilities from './CardAbilities';
import Phases, { Phase } from './Phases';
import * as TributeLogic from './TributeLogic';
import * as Utility from './Utility';

import { GameOptions } from './GameOptions';
import { CardDef, TributeDef } from './GameModel';
import { clamp } from './Utility';
import { Token } from './Resources';
import { InflatedGame, InflatedPlayer } from './Game';
import {
  ConflictResultsPayload,
  EventTypes,
  GameEventOfType,
} from './GameEvents';
import { MAX_GOLD, MAX_MILITARY } from './GameConstants';

export type CardWithID = CardDef & { id: string; index: number };
export type TributeCardWithID = TributeDef & {
  id: string;
  index: number;
};

export interface Bid {
  tradeRowIndex: number;
  military: number;
}
export type ResolutionSelection = {
  cardIDToGain: string;
};

export type RerollType = 'na' | 'decline' | 'reroll';
export type RerollSelection = {
  rerollType: RerollType;
  rerollRound: number;
};

export interface Context {
  player: InflatedPlayer;
  turn: number;
  age: number;

  options: GameOptions;

  // counters produced this turn
  production_delta: Utility.CounterDelta;
  // counters spent this turn
  spent_delta: Utility.CounterDelta;
  // counters generated by drafted card
  card_delta: Utility.CounterDelta;

  tribute_favor: number;

  completedTributeIDs: string[];

  // counters generated by triggered cards
  triggerDelta: Utility.CounterDelta;
  triggeredCardIDs: string[];
}

export function makeContext(
  player: InflatedPlayer,
  turn: number,
  age: number,
  options: GameOptions,
): Context {
  return {
    player,
    turn,
    age,

    options,

    spent_delta: Utility.makeCounterDelta(),
    production_delta: Utility.makeCounterDelta(),
    card_delta: Utility.makeCounterDelta(),

    tribute_favor: 0,

    completedTributeIDs: [],

    triggerDelta: Utility.makeCounterDelta(),
    triggeredCardIDs: [],
  };
}
export function makeContextFromGame(
  player: InflatedPlayer,
  game: InflatedGame,
): Context {
  return makeContext(player, game.turn, game.age, game.options);
}

export function computeBaseCounters(context: Context): Utility.CounterDelta {
  let ret = Utility.makeCounterDelta();
  context.player.cards.forEach((card) => {
    const baseAbility = CardAbilities.getBaseCountersAbility(card);
    if (!baseAbility) {
      return;
    }
    Utility.applyCounterDelta(ret, baseAbility.getBaseCounters(context));
  });
  return ret;
}

export type CanAffordCardResult =
  | {
      canAfford: true;
    }
  | {
      canAfford: false;
      reason: string;
    };
export function canAffordCard(
  context: Context,
  card: CardDef,
): CanAffordCardResult {
  const baseCounters = computeBaseCounters(context);
  if (card.cost > context.player.counters.gold + baseCounters.gold) {
    return {
      canAfford: false,
      reason: 'Not enough gold',
      // reason: 'Insufficient gold to buy card.',
    };
  }

  const additionalCosts = CardAbilities.getAdditionalCosts(card);
  if (additionalCosts?.warTokens) {
    if (additionalCosts.warTokens > context.player.counters.warTokens) {
      return {
        canAfford: false,
        reason: 'Not enough Clash tokens',
        // reason: 'Insufficient war tokens to buy card.',
      };
    }
  }

  return {
    canAfford: true,
  };
}

export function resolveContextCounters(context: Context): void {
  const player = context.player;

  context.production_delta.gold = clamp(
    context.production_delta.gold,
    -player.counters.gold,
    MAX_GOLD - player.counters.gold,
  );
  context.production_delta.military = clamp(
    context.production_delta.military,
    -player.counters.military,
    MAX_MILITARY - player.counters.military,
  );
  Utility.applyCounterDelta(player.counters, context.production_delta);

  Utility.applyCounterDelta(
    player.counters,
    Utility.multiplyCounters(context.spent_delta, -1),
  );
  player.counters.gold = clamp(player.counters.gold, 0, MAX_GOLD);
  player.counters.military = clamp(player.counters.military, 0, MAX_MILITARY);
}

export function handleBid(context: Context, bid: Bid): Context {
  context.spent_delta.military += bid.military;
  return context;
}

export function handleGainCard(
  context: Context,
  gainedCard: CardWithID,
): Context {
  const new_context = { ...context };

  context.card_delta.favor += gainedCard.favor;
  // Drafted card "on gain" favor
  const onGainAbility = CardAbilities.getGainSelfCardAbility(gainedCard);
  if (onGainAbility) {
    const output = onGainAbility.onGainSelf(context);
    Utility.applyCounterDelta(context.card_delta, output);
  }
  Utility.applyCounterDelta(context.production_delta, context.card_delta);

  // Existing cards "on gain card"
  let triggerDelta = Utility.makeCounterDelta();
  context.player.cards.forEach((card) => {
    if (card === gainedCard) {
      return;
    }
    const onGainAbility = CardAbilities.getGainOtherCardAbility(card);
    if (onGainAbility) {
      const output = onGainAbility.onGainCard(context, gainedCard);
      if (!Utility.isZeroCounterDelta(output)) {
        new_context.triggeredCardIDs.push(card.id);
      }
      Utility.applyCounterDelta(triggerDelta, output);
    }
  });

  Utility.applyCounterDelta(new_context.triggerDelta, triggerDelta);
  Utility.applyCounterDelta(new_context.production_delta, triggerDelta);

  return new_context;
}

export function handleResolutionSelection(
  context: Context,
  draftedCard: CardWithID,
  cardToGain: CardWithID,
): Context {
  const new_context = { ...context };

  const baseCounters = computeBaseCounters(context);

  // Pay for card
  new_context.spent_delta.gold += Math.max(
    0,
    cardToGain.cost - baseCounters.gold,
  );
  const additionalCosts = CardAbilities.getAdditionalCosts(cardToGain);
  if (additionalCosts?.warTokens) {
    new_context.spent_delta.warTokens += additionalCosts.warTokens;
  }

  // Existing cards "on draft card"
  context.player.cards.forEach((card) => {
    const onDraftAbility = CardAbilities.getDraftCardAbility(card);
    if (onDraftAbility) {
      const output = onDraftAbility.onDraftCard(context, draftedCard);
      Utility.applyCounterDelta(new_context.production_delta, output);
    }
  });

  return new_context;
}

export function handleProduction(context: Context): Context {
  const new_context = { ...context };

  // Existing cards "on production"
  context.player.cards.forEach((card) => {
    const onProductionAbility = CardAbilities.getEachTurnCardAbility(card);
    if (onProductionAbility) {
      const output = onProductionAbility.onTurn(context);
      Utility.applyCounterDelta(new_context.production_delta, output);
    }
  });

  return new_context;
}

export function handleTokenGain(context: Context, token: Token): Context {
  const new_context = { ...context };

  // Existing cards "on gain other"
  let triggerDelta = Utility.makeCounterDelta();
  new_context.player.cards.forEach((card) => {
    const output = CardAbilities.getGainOtherCardAbility(card)?.onGainToken(
      context,
      token,
    );
    if (output && !Utility.isZeroCounterDelta(output)) {
      Utility.applyCounterDelta(triggerDelta, output);
      new_context.triggeredCardIDs.push(card.id);
    }
  });

  Utility.applyCounterDelta(new_context.triggerDelta, triggerDelta);
  Utility.applyCounterDelta(new_context.production_delta, triggerDelta);

  return new_context;
}

export function handleTributeGain(
  context: Context,
  tributes: TributeCardWithID[],
  planning_data_by_tribute: {
    [k: string]: TributeLogic.TributeLogicPlanningData;
  },
  resolution_data_by_tribute: {
    [k: string]: TributeLogic.TributeLogicResolutionData;
  },
  production_data_by_tribute: {
    [k: string]: TributeLogic.TributeLogicResolutionData;
  },
  players: InflatedPlayer[],
  conflictResults: GameEventOfType<EventTypes.CONFLICT_RESULTS> | null,
): Context {
  let check_tributes = true;
  let new_context = context;

  while (check_tributes) {
    check_tributes = false;
    tributes.forEach((tribute) => {
      if (new_context.completedTributeIDs.includes(tribute.id)) {
        return;
      }

      const tributeLogic = TributeLogic.getTributeLogic(tribute);
      invariant(tributeLogic, 'no card function for tribute %s', tribute.name);

      const planning_data = planning_data_by_tribute[tribute.id] ?? null;
      const resolution_data = resolution_data_by_tribute[tribute.id] ?? null;
      const production_data = production_data_by_tribute[tribute.id] ?? null;
      if (
        tributeLogic.shouldGainCard(
          new_context,
          planning_data,
          resolution_data,
          production_data,
          players,
          conflictResults,
        )
      ) {
        let tribute_favor = tributeLogic.favor[context.age - 1];
        new_context = { ...new_context };
        new_context.completedTributeIDs.push(tribute.id);
        new_context.production_delta.favor += tribute_favor;
        new_context.tribute_favor += tribute_favor;
        check_tributes = true;

        // Existing cards "on gain tribute card"
        context.player.cards.forEach((card) => {
          const output =
            CardAbilities.getGainOtherCardAbility(card)?.onGainTribute(
              new_context,
            );
          if (output && !Utility.isZeroCounterDelta(output)) {
            Utility.applyCounterDelta(new_context.production_delta, output);
            Utility.applyCounterDelta(new_context.triggerDelta, output);
            new_context.triggeredCardIDs.push(card.id);
          }
        });
      }
    });
  }
  return new_context;
}

export function endOfGamePointsPerToken(gameOptions: GameOptions): {
  gold: number;
  military: number;
} {
  return {
    gold: 5,
    military: 5,
  };
}

export function handleEndGameScoring(context: Context): Context {
  const new_context = { ...context };

  // Existing cards "on end game"
  // context.player.cards.forEach((card) => {
  //   const onEndGameAbility = CardAbilities.getEndGameCardAbility(card);
  //   if (onEndGameAbility) {
  //     const output = onEndGameAbility.onEndGame(context);
  //     Utility.applyCounterDelta(new_context.production_delta, output);
  //   }
  // });

  const pointsPerToken = endOfGamePointsPerToken(context.options);

  let goldPerFavor = pointsPerToken.gold;
  let militaryPerFavor = pointsPerToken.military;

  new_context.production_delta.favor += Math.floor(
    new_context.player.counters.gold / goldPerFavor,
  );
  new_context.spent_delta.gold += context.player.counters.gold;

  new_context.production_delta.favor += Math.floor(
    new_context.player.counters.military / militaryPerFavor,
  );
  new_context.spent_delta.military += context.player.counters.military;

  return new_context;
}

export interface GameInfo {
  basicPile: CardWithID[];
  tradeRow: CardWithID[];
  cardsByID: { [id: string]: CardWithID | TributeCardWithID };
  turn: number;
  phase: Phase;
  age: number;
  options: GameOptions;
}

export type CanPlayerRerollResult = {
  result: boolean;
  message?: string;
};
export function canPlayerReroll(
  player: InflatedPlayer,
  lastConflictResult: ConflictResultsPayload,
): CanPlayerRerollResult {
  // you may only reroll if you have a reroll token and you did not win the clash
  if (player.counters.rerollTokens <= 0) {
    return {
      result: false,
      message: 'No reroll tokens',
    };
  }
  if (lastConflictResult.winningPlayerID === player.userID) {
    return {
      result: false,
      message: 'Rerolls only available on losses',
    };
  }
  return {
    result: true,
  };
}

export function validateBid(
  player: InflatedPlayer,
  bid: Bid,
  game_info: GameInfo,
) {
  let { phase } = game_info;

  if (phase !== Phases.PLANNING) {
    throw new Error('You can only bid during the planning phase.');
  }
  if (
    typeof bid.military !== 'number' ||
    bid.military < 0 ||
    bid.military > player.counters.military
  ) {
    throw new Error('Invalid military bid.');
  }
  if (bid.tradeRowIndex < 0 || bid.tradeRowIndex > game_info.tradeRow.length) {
    throw new Error('Invalid trade row index.');
  }
}

export function validateResolve(
  player: InflatedPlayer,
  resolution: ResolutionSelection,
  game_info: GameInfo,
) {
  let { phase } = game_info;

  if (phase !== Phases.RESOLUTION) {
    throw new Error('You can only resolve during the resolution phase.');
  }

  let cardToGain: CardWithID | null = null;
  const basicCard = game_info.basicPile.find(
    (card) => card.id === resolution.cardIDToGain,
  );
  if (basicCard) {
    cardToGain = basicCard;
  } else {
    const bid = player.bid;
    if (!bid) {
      throw new Error('Missing bid.');
    }

    const bidCard = game_info.tradeRow[bid.tradeRowIndex];
    if (
      player.selectedCard?.id !== resolution.cardIDToGain ||
      bidCard.id !== resolution.cardIDToGain
    ) {
      throw new Error('Invalid card selection.');
    }
    cardToGain = bidCard;
  }
  invariant(cardToGain, 'card to gain not found');

  const context = makeContext(
    player,
    game_info.turn,
    game_info.age,
    game_info.options,
  );
  const canAfford = canAffordCard(context, cardToGain);
  if (!canAfford.canAfford) {
    throw new Error(canAfford.reason);
  }
}
