/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import { times } from 'underscore';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import CardView, { BasicCardRenderer } from './CardView';
import PileView from './PileView';
import PilesContainer from './PilesContainerView';
import HoverCardStore from './HoverCardStore';
import PageHeaderView from './PageHeaderView';
import Session from './Session';
import * as Rules from '../game/Rules';
import { CardType } from '../game/CardTypes';
import { GameDefinitions } from '../game/GameModel';
import { match } from 'ts-pattern';
import { CardBackView, CardBackType, CardBack } from './CardBackView';
import { fetchGameDefinitions } from './actions';
import TributeCardView from './TributeCardView';
import invariant from 'invariant';
import LogoBanner from './LogoBanner';

interface Props {
  session?: Session;
  dispatch: (action: any) => void;
  gameDefinitions: GameDefinitions | null;
}

function AllCardsPage(props: Props) {
  const { session, dispatch, gameDefinitions } = props;

  useEffect(() => {
    dispatch(fetchGameDefinitions());
  }, []);

  useEffect(() => {
    if (gameDefinitions) {
      // scroll to hash
      const hash = window.location.hash;
      if (hash) {
        const element = document.getElementById(hash.slice(1));
        if (element) {
          element.scrollIntoView();
        }
      }
    }
  }, [gameDefinitions]);

  const cardsByAge: {
    [k: number]: (Rules.CardWithID | CardBack)[];
    basic: (Rules.CardWithID | CardBack)[];
    leader: (Rules.CardWithID | CardBack)[];
  } = { basic: [], leader: [] };

  const cardDefs = gameDefinitions?.cardDefs || [];
  const tributeCardDefs = gameDefinitions?.tributeCardDefs || [];
  const cards = cardDefs.map((x, i) => ({
    ...x,
    index: i,
    id: `card${i}`,
  }));
  const tributeCards = tributeCardDefs.map((x, i) => ({
    ...x,
    index: i,
    id: `tribute${i}`,
  }));

  cardsByAge.leader = cards.filter((c) => c.type === CardType.Leader);
  const basicCards = cards.filter((c) => c.type === CardType.Basic);
  cardsByAge.basic = [
    // {
    //   id: 'card-back-basic1',
    //   type: 'basic1' as CardBackType,
    // },
    ...basicCards.filter((c) => c.age === 1),
    // {
    //   id: 'card-back-basic2',
    //   type: 'basic2' as CardBackType,
    // },
    ...basicCards.filter((c) => c.age === 2),
    // {
    //   id: 'card-back-basic3',
    //   type: 'basic3' as CardBackType,
    // },
    ...basicCards.filter((c) => c.age === 3),
    ...basicCards.filter((c) => c.age !== 1 && c.age !== 2 && c.age !== 3),
  ];
  times(3, function (i) {
    cardsByAge[i] = cards.filter((c) => {
      return c.age === i + 1 && c.type !== CardType.Basic;
    });
    cardsByAge[i].unshift({
      id: `card-back-age${i + 1}`,
      type: `age${i + 1}` as CardBackType,
    });
  });

  type AllCardsSection = {
    title: string;
    linkHash: string;
    cards: { id: string }[];
    cardBack?: { id: string; type: CardBackType };
  };
  const sections: AllCardsSection[] = [
    {
      title: 'Leaders',
      linkHash: 'leader',
      cards: cardsByAge.leader,
    },
    {
      title: 'Basic Cards',
      linkHash: 'basic',
      cards: cardsByAge.basic,
    },
    {
      title: 'Age 1',
      linkHash: 'age-1',
      cards: cardsByAge[0],
    },
    {
      title: 'Age 2',
      linkHash: 'age-2',
      cards: cardsByAge[1],
    },
    {
      title: 'Age 3',
      linkHash: 'age-3',
      cards: cardsByAge[2],
    },
    {
      title: 'Tributes',
      linkHash: 'tributes',
      cards: tributeCards,
    },
  ];

  gameDefinitions?.modules.forEach((module) => {
    const cards = module.cardDefs.map((x, i) => ({
      ...x,
      index: i,
      id: `card${i}`,
    }));
    const tributeCards = module.tributeCardDefs.map((x, i) => ({
      ...x,
      index: i,
      id: `tribute${i}`,
    }));

    sections.push({
      title: module.name,
      linkHash: module.key,
      cards: [...cards, ...tributeCards],
    });
  });

  const renderer = (props: any) => {
    var card = props.card;
    return match(card.id)
      .when(
        (id) => id.startsWith('card-back'),
        () => <CardBackView {...props} />,
      )
      .when(
        (id) => id.startsWith('tribute'),
        () => <TributeCardView {...props} />,
      )
      .when(
        (id) => id.startsWith('card'),
        () => <CardView {...props} />,
      )
      .otherwise((x) => invariant(false, 'unknown card id ' + x));
  };
  const hover_renderer = renderer;

  return (
    <div css={AllCardsStyles.container}>
      <PageHeaderView session={session} />
      <LogoBanner />
      <div css={AllCardsStyles.linksContainer}>
        {sections.map(({ title, linkHash }) => {
          return (
            <div key={linkHash} style={{ margin: 5 }}>
              <a css={AllCardsStyles.link} href={`#${linkHash}`} key={linkHash}>
                {title}
              </a>
            </div>
          );
        })}
      </div>
      <div>
        {sections.map(({ title, cards, linkHash }, i) => {
          return (
            <PilesContainer id={linkHash} key={i} title={title}>
              <PileView
                renderer={renderer}
                hoverRenderer={hover_renderer}
                cards={cards}
              />
            </PilesContainer>
          );
        })}
        {HoverCardStore.getHoverCardComponent()}
      </div>
    </div>
  );
}

const AllCardsStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  }),
  linksContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

    alignSelf: 'center',

    backgroundColor: 'rgb(80, 77, 83)',
    padding: 4,
    paddingLeft: 30,
    paddingRight: 30,
    // margin: 25,
  }),
  link: css({
    textDecoration: 'none',
    // color: 'rgb(254, 198, 10)',
    margin: 8,

    color: 'rgb(220, 200, 130)',
    textAlign: 'center',
    '&:hover': {
      color: 'rgb(240, 220, 150)',
    },
  }),
};

function session(state: any) {
  return {
    session: state.session as Session | undefined,
    gameDefinitions: state.gameDefinitions as GameDefinitions | null,
  };
}

export default connect(session)(AllCardsPage);
