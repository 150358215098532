/** @jsx jsx */
/** @jsxFrag */
import { jsx, css } from '@emotion/react';
import {
  ConflictRoundInfo,
  EventTypes,
  GameEventOfType,
} from '../game/GameEvents';
import Symbols, { SymbolView } from './Symbols';
import DieView from './DieView';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { once, sortBy } from 'underscore';
import { EffectPill } from './CardHelpers';
import { match } from 'ts-pattern';
import Phases, { Phase } from '../game/Phases';

type User = any;

type ConflictResultsDisplayState =
  | 'initial'
  | 'rolling'
  | 'results'
  | 'completed';
export type ConflictResultsDisplayRef = {
  startAnimation: () => void;
};
type ConflictResultsDisplayProps = {
  rounds: ConflictRoundInfo[];
  winningPlayerID?: string;
  userByID: { [k: string]: User };
  onCompleted: () => void;
  animate: boolean;
};
export const ConflictResultsDisplay = forwardRef<
  ConflictResultsDisplayRef,
  ConflictResultsDisplayProps
>((props, ref) => {
  const { rounds, winningPlayerID, userByID } = props;

  useImperativeHandle(ref, () => ({
    startAnimation,
  }));

  const firstRound = rounds[0];
  let playerIDs = Object.keys(firstRound);
  const playerIDToBonus = Object.fromEntries(
    playerIDs.map((playerID) => [playerID, firstRound[playerID].bonus]),
  );

  playerIDs = sortBy(playerIDs, (playerID) => {
    return -playerIDToBonus[playerID];
  });

  const [state, setState] = useState<ConflictResultsDisplayState>(
    playerIDs.length > 1 &&
      props.animate &&
      Object.values(firstRound).some((x) => x.didRoll)
      ? 'initial'
      : 'completed',
  );
  const [roundIndex, setRoundIndex] = useState(0);
  const completedRef = useRef(false);

  const round = roundIndex >= 0 ? rounds[roundIndex] : null;
  const isLastRound = roundIndex === rounds.length - 1;

  const notifyCompleted = () => {
    if (completedRef.current) {
      return;
    }
    completedRef.current = true;
    props.onCompleted();
  };

  const startedRef = useRef(false);
  const startAnimation = () => {
    if (startedRef.current) {
      return;
    }
    startedRef.current = true;
    if (state === 'completed') {
      notifyCompleted();
      return;
    }

    if (state !== 'initial') {
      return;
    }
    setState('rolling');
  };

  const advanceState = once(() => {
    if (state !== 'rolling') {
      return;
    }

    if (!isLastRound) {
      setState('results');
      setTimeout(advanceRound, 1500);
    } else {
      setState('completed');
      setTimeout(notifyCompleted, 1500);
    }
  });
  const advanceRound = () => {
    setRoundIndex((i) => i + 1);
    setState('rolling');
  };

  return (
    <div css={ConflictResultsStyles.container}>
      {playerIDs.map((playerID) => {
        const playerRound = round && round[playerID];
        let total = !playerRound
          ? '💀'
          : match(state)
              .with('initial', () => '')
              .with('rolling', () => '?')
              .with('results', () => playerRound?.total || 'XXX')
              .with('completed', () => playerRound?.total || 'XXX')
              .exhaustive();
        const isWinner = playerID === winningPlayerID;
        const isLoser = winningPlayerID && playerID !== winningPlayerID;
        return (
          <div
            key={playerID}
            css={ConflictResultsStyles.playerContainer}
            style={
              state === 'completed' && isLastRound
                ? {
                    backgroundColor: isLoser ? 'rgba(0, 0, 0, 0.5)' : undefined,
                    order: isWinner ? -1 : 0,
                  }
                : {}
            }
          >
            <div css={ConflictResultsStyles.splitter}>
              <span css={ConflictResultsStyles.name}>
                {isWinner && state === 'completed' ? '\u2605' : ''}
                {userByID[playerID].name}
              </span>
              <span>{total}</span>
            </div>
            <div css={ConflictResultsStyles.splitter}>
              <span>
                <SymbolView
                  symbol={Symbols.MILITARY}
                  css={ConflictResultsStyles.militarySymbol}
                />
                {playerIDToBonus[playerID]}
              </span>
              <div>
                {state !== 'initial' &&
                  playerRound &&
                  playerRound.rolls.map((roll, i) => {
                    return (
                      <DieView
                        key={i}
                        roll={roll}
                        animate={state === 'rolling' && playerRound.didRoll}
                        onAnimateEnd={advanceState}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});

export const ResolutionPhaseConflictResultsDisplay = (props: {
  event: GameEventOfType<EventTypes.CONFLICT_RESULTS>;
  userByID: { [k: string]: User };
}) => {
  const { event, userByID } = props;
  const firstRound = event.payload.rounds[0];
  let playerIDs = Object.keys(firstRound);
  const playerIDToBonus = Object.fromEntries(
    playerIDs.map((playerID) => [playerID, firstRound[playerID].bonus]),
  );

  playerIDs = sortBy(playerIDs, (playerID) => {
    if (event.payload.winningPlayerID === playerID) {
      return -Infinity;
    }
    return -playerIDToBonus[playerID];
  });

  return (
    <div css={ConflictResultsStyles.container}>
      {playerIDs.map((playerID) => {
        const isWinner = playerID === event.payload.winningPlayerID;
        return (
          <div
            key={playerID}
            css={ConflictResultsStyles.playerContainer}
            style={{
              opacity: !isWinner ? 0.5 : 1,
            }}
          >
            <div css={ConflictResultsStyles.splitter}>
              <span css={ConflictResultsStyles.name}>
                {isWinner ? '\u2605' : ''}
                {userByID[playerID].name}
              </span>
              <EffectPill
                output={{
                  outputResource: 'military',
                  outputRatio: playerIDToBonus[playerID],
                }}
                hideChrome
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ConflictResultsStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    backgroundColor: 'rgba(210, 210, 210, 0.9)',
    // backdropFilter: 'blur(5px)',
    borderRadius: 4,
    padding: 4,

    width: 80,
  }),
  splitter: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // height: 20,
  }),
  playerContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    fontSize: 12,
    paddingTop: 1,
    paddingBottom: 1,

    '&:not(:last-child)': {
      marginBottom: 2,
    },
    transition: 'background-color 0.5s, order 1.5s',
  }),
  name: css({
    fontSize: 12,
    fontWeight: 'bold',
    paddingBottom: 4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  militarySymbol: css({
    height: 16,
    width: 'auto',
    margin: 2,
    verticalAlign: 'middle',
    marginTop: 0,
  }),
};
