import _ from 'underscore';

import { CardSetTypes } from './CardTypes';
import { CardWithID } from './Rules';

export function countBoardSets(board: CardWithID[]) {
  var counts = _.countBy(board, (card) => {
    return card.type;
  });
  return _.min(
    CardSetTypes.map((type) => {
      return counts[type] || 0;
    }),
  );
}

export interface CounterDelta {
  gold: number;
  favor: number;
  military: number;
  warTokens: number;
  rerollTokens: number;
}
export function makeCounterDelta(): CounterDelta {
  return {
    gold: 0,
    favor: 0,
    military: 0,
    warTokens: 0,
    rerollTokens: 0,
  };
}

export function addCounters(
  counters: CounterDelta,
  other: CounterDelta,
): CounterDelta {
  const output = { ...counters };
  output.gold += other.gold;
  output.military += other.military;
  output.favor += other.favor;
  output.warTokens += other.warTokens;
  output.rerollTokens += other.rerollTokens;
  return output;
}

export function applyCounterDelta(
  target: CounterDelta,
  delta: Partial<CounterDelta>,
): CounterDelta {
  target.gold += delta.gold || 0;
  target.military += delta.military || 0;
  target.favor += delta.favor || 0;
  target.warTokens += delta.warTokens || 0;
  target.rerollTokens += delta.rerollTokens || 0;
  return target;
}

export function multiplyCounters(
  counters: CounterDelta,
  factor: number,
): CounterDelta {
  const output = { ...counters };
  output.gold *= factor;
  output.military *= factor;
  output.favor *= factor;
  output.warTokens *= factor;
  output.rerollTokens *= factor;
  return output;
}

export function isZeroCounterDelta(counters: CounterDelta): boolean {
  return (
    counters.gold === 0 &&
    counters.military === 0 &&
    counters.favor === 0 &&
    counters.warTokens === 0 &&
    counters.rerollTokens === 0
  );
}

export function countIf<T>(list: Iterable<T>, iterator: (v: T) => boolean) {
  let count = 0;
  for (const v of list) {
    if (iterator(v)) {
      count++;
    }
  }
  return count;
}

_.mixin({
  sum: function <T>(list: Iterable<T>, iterator: (v: T) => number) {
    let sum = 0;
    for (const v of list) {
      sum += iterator(v);
    }
    return sum;
  },
});

export function sum<T>(list: Iterable<T>, iterator: (v: T) => number) {
  let sum = 0;
  for (const v of list) {
    sum += iterator(v);
  }
  return sum;
}

export function ageString(age: number): string {
  return 'I'.repeat(age);
}

export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}
