import _ from 'underscore';

export const Colors = [
  'rgb(21, 103, 205)', //blue
  'rgb(117, 73, 205)', //purple
  'rgb(224, 139, 20)', //yellow
  'rgb(20, 154, 32)', //green
  'rgb(205, 27, 40)', //red
  'rgb(20, 136, 137)', //teal
  'rgb(58, 153, 205)', //baby blue
  'rgb(205, 69, 36)', //red orange;
];

function colorToHex(color: string) {
  if (color.substring(0, 1) === '#') {
    return color;
  }
  const digits = /(.*?)rgb\((\d+), (\d+), (\d+)\)/.exec(color);
  if (!digits) {
    return color;
  }

  const red = parseInt(digits[2]);
  const green = parseInt(digits[3]);
  const blue = parseInt(digits[4]);

  const rgb = blue | (green << 8) | (red << 16);
  return digits[1] + '#' + rgb.toString(16);
}

export const ColorsHash = Colors.map(colorToHex);
