/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  fetchLobbyList,
  subscribeToChannelTopic,
  unsubscribeToChannelTopic,
} from './actions';
import Session from './Session';
import LobbyMutator from './LobbyMutator';
import LobbyButton from './LobbyButton';

type Lobby = {
  id: string;
  name: string;
  creationDate: number;
  status: string;
  game?: {
    id: string;
    endTimestamp?: number;
  };
  host: { userID: string; name: string; profilePictureURL: string };
  players: {
    edges: any[];
    nodes: any[];
  };
};

const LobbyListEntry: React.FC<{
  lobby: Lobby;
  sessionUserID?: string;
  onJoin: (lobby: Lobby) => void;
  onSpectate: (lobby: Lobby) => void;
}> = (props) => {
  const { lobby, sessionUserID, onJoin, onSpectate } = props;
  const game = lobby.game;

  let connect_section: JSX.Element;
  if (game) {
    const contains_session_user = lobby.players.edges.some((edge: any) => {
      return edge.node.id === sessionUserID;
    });
    let button_text = contains_session_user ? 'Resume Game' : 'Spectate';
    if (game.endTimestamp) {
      button_text = 'View Results';
    }
    connect_section = (
      <LobbyButton secondary={true} onClick={() => onSpectate(lobby)}>
        {button_text}
      </LobbyButton>
    );
  } else {
    connect_section = (
      <LobbyButton onClick={() => onJoin(lobby)}>Join Lobby</LobbyButton>
    );
  }
  const Cell = (props: { className?: string; children: React.ReactNode }) => (
    <td className={props.className} css={LobbyListStyles.tableCell}>
      {props.children}
    </td>
  );
  return (
    <tr>
      <Cell>{lobby.name}</Cell>
      <Cell css={LobbyListStyles.tableCellHost}>
        <img src={lobby.host.profilePictureURL} css={LobbyListStyles.profile} />
        <span css={LobbyListStyles.hostName}>{lobby.host.name}</span>
      </Cell>
      <Cell css={LobbyListStyles.tableCellSmall}>
        {lobby.players.edges.length}
      </Cell>
      <Cell css={LobbyListStyles.tableCellSmall}>
        {moment(lobby.creationDate).fromNow()}
      </Cell>
      <Cell css={LobbyListStyles.tableCellSmall}>
        <span css={LobbyListStyles.tableSubCell}>{lobby.status}</span>
      </Cell>
      <Cell>{connect_section}</Cell>
    </tr>
  );
};

function LobbyListView(props: { session?: Session; lobbies: Lobby[] }) {
  const { session, lobbies } = props;
  const navigate = useNavigate();
  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const onLobbyJoin = useCallback(
    async (lobby: Lobby) => {
      var mutator = new LobbyMutator(session, lobby.id);
      await mutator.joinGame();
      navigate('/lobby/' + lobby.id);
    },
    [navigate, session],
  );
  const onLobbySpectate = useCallback(
    (lobby: Lobby) => {
      if (!lobby.game) {
        return;
      }
      navigate('/game/' + lobby.game.id);
    },
    [navigate],
  );
  const onCreateLobby = useCallback(async () => {
    if (requestInProgress) {
      return;
    }
    setRequestInProgress(true);

    const res = await fetch('/api/lobby/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ host_id: session!.getUser().id }),
    });
    if (!res.ok) {
      alert('error creating lobby ' + res.text);
      setRequestInProgress(false);
      return;
    }

    const lobby = await res.json();
    navigate('/lobby/' + lobby.id);
  }, [navigate, requestInProgress, setRequestInProgress]);

  const user = session && session.getUser();
  const lobbyViews = lobbies.map((lobby) => {
    return (
      <LobbyListEntry
        key={lobby.id}
        lobby={lobby}
        sessionUserID={user && user.id}
        onJoin={onLobbyJoin}
        onSpectate={onLobbySpectate}
      />
    );
  });
  const HeaderCell = (props: {
    className?: string;
    children: React.ReactNode;
  }) => (
    <td
      className={props.className}
      css={[LobbyListStyles.tableCell, LobbyListStyles.tableCellHeader]}
    >
      {props.children}
    </td>
  );

  return (
    <div css={LobbyListStyles.container}>
      <div css={LobbyListStyles.header}>
        <LobbyButton css={LobbyListStyles.createButton} onClick={onCreateLobby}>
          Create Lobby
        </LobbyButton>
      </div>
      <div css={LobbyListStyles.tableContainer}>
        <table css={LobbyListStyles.table}>
          <thead>
            <tr css={LobbyListStyles.tableHeader}>
              <HeaderCell>Lobby</HeaderCell>
              <HeaderCell>Host</HeaderCell>
              <HeaderCell css={LobbyListStyles.tableCellSmall}>
                Players
              </HeaderCell>
              <HeaderCell css={LobbyListStyles.tableCellSmall}>
                Created
              </HeaderCell>
              <HeaderCell css={LobbyListStyles.tableCellSmall}>
                Status
              </HeaderCell>
              <HeaderCell>Play</HeaderCell>
            </tr>
          </thead>
          <tbody>{lobbyViews}</tbody>
        </table>
      </div>
    </div>
  );
}

const LobbyListStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  header: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',

    fontSize: 16,
    margin: 20,
    marginBottom: 5,
    padding: 5,
  }),
  createButton: css({
    margin: 0,
    padding: '10px 80px 10px 80px',
    fontSize: 16,
  }),
  tableContainer: css({
    margin: 20,
    marginTop: 0,
  }),
  table: css({
    width: '100%',
    backgroundColor: 'rgb(120, 120, 120)',
  }),
  tableHeader: css({
    backgroundColor: 'rgb(95, 95, 95)',
    color: 'rgb(231, 231, 231)',
    borderBottom: '1px solid rgb(85, 85, 85)',
  }),
  tableCell: css({
    textAlign: 'center',
    paddingLeft: 20,
    paddingRight: 20,

    borderLeft: '1px solid rgb(110, 110, 110)',
    borderRight: '1px solid rgb(110, 110, 110)',
    borderBottom: '1px solid rgb(90, 90, 90)',
  }),
  tableCellHeader: css({
    padding: 10,
    borderColor: 'rgb(85, 85, 85)',
  }),
  tableCellSmall: css({
    paddingLeft: 5,
    paddingRight: 5,
  }),
  tableSubCell: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  }),
  tableCellHost: css({
    textAlign: 'left',
    paddingLeft: 5,
  }),
  hostName: css({
    textAlign: 'center',
  }),
  profile: css({
    height: 28,
    width: 28,
    margin: '5px 10px 5px 0px',
    verticalAlign: 'middle',
  }),
};

function LobbyListPage(props: {
  dispatch: any;
  lobbyList: Lobby[];
  session?: Session;
}) {
  const { dispatch, lobbyList, session } = props;

  useEffect(() => {
    dispatch(fetchLobbyList());

    const subscription = dispatch(
      subscribeToChannelTopic(`lobby_list`, () => {
        dispatch(fetchLobbyList());
      }),
    );
    return () => {
      dispatch(unsubscribeToChannelTopic(subscription));
    };
  }, []);

  if (!lobbyList) {
    return null;
  }
  return <LobbyListView lobbies={lobbyList} session={session} />;
}

function select(state: any) {
  return {
    lobbyList: state.lobbyList as Lobby[],
    session: state.session as Session | undefined,
  };
}

export default connect(select)(LobbyListPage);
