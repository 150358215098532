import _, { max, min } from 'underscore';

import Bot from '../game/Bot';
import { affordableCards, totalProductionCounters } from '../game/AIUtilities';
import Phases from '../game/Phases';
import * as Rules from '../game/Rules';
import * as TributeLogic from '../game/TributeLogic';
import * as CardAbilities from '../game/CardAbilities';
import * as AIUtilities from '../game/AIUtilities';
import { InflatedGame, InflatedPlayer } from '../game/Game';
import { CardType } from '../game/CardTypes';
import { CounterDelta, addCounters } from '../game/Utility';
import { randomSelect } from '../utils/utils';
import { getBaseCountersAbility } from '../game/CardAbilities';
import { Resource } from '../game/Resources';
import { probabilityWinningMultiBattle } from '../game/Probability';

//TODO: keep track players not spending their military to weight their effective military for consideration
//TODO: compute amount of military to spend based on 1. how much more military you have over opponent, 2. how much to spend based on EV
//TODO: consider a 'confidence' in the selection and whether to spend no military
//TODO: factor in synergy cards and their desirability to opponents
//TODO: check, i think the basic card selection is inflating value of mercanary
//TODO: clean up code, move into helper functions, consider perf improvments

// All preferences are a real number between 0 and 1, and positively correlated
export interface Preferences {
  card_type_preferences: Record<CardType, number>;
  card_output_values_by_age: Record<Resource, number[]>;
  chaos: number;
  target_gold_output_by_age: number[]; // production + base
  military_supremacy_target: number; // how much more military output to have over opponents
  military_supremacy_preference: number; // how much to weigh that target
  card_flat_favor_preference_by_age: number[];
  target_card_type_by_age: Record<CardType, number[]>; // how many of each card type to have within age

  // Basic Bot 2 preferences
  conserve_military_factor_by_age: number[]; // how much to weigh conserving military (should be 0-1.0)
  card_cost_value_reduction_by_age: number[]; // how much to reduce value by cost of card
  bid_nothing_threshold_by_age: number[]; // value threshold
  bid_nothing_probability_by_age: number[];
}

export default class BasicBot extends Bot {
  // card prefences
  preferences_: Preferences;
  verbose: boolean = false;

  CANT_AFFORD_VALUE_DEDUCTION = 10.0;

  constructor(preferences?: Preferences) {
    super();
    this.preferences_ = preferences || {
      card_type_preferences: {
        [CardType.Leader]: 1,
        [CardType.Basic]: 1,
        [CardType.Resource]: 1,
        [CardType.Conflict]: 1,
        [CardType.Prayer]: 1,
      } as Record<CardType, number>,
      card_output_values_by_age: {
        // TODO: revisit -- factor in turn/age etc and current production
        gold: [0.5, 0.5, 0.5],
        military: [0.5, 0.5, 0.25],
      } as Record<Resource, number[]>,

      chaos: 0.001,
      target_gold_output_by_age: [4, 7, 8],
      military_supremacy_target: 1,
      military_supremacy_preference: 0.5,
      card_flat_favor_preference_by_age: [1, 1.5, 2],
      target_card_type_by_age: {
        [CardType.Leader]: [1, 1, 1],
        [CardType.Basic]: [0, 0, 0],
        [CardType.Resource]: [2, 4, 5],
        [CardType.Conflict]: [2, 3, 5],
        [CardType.Prayer]: [1, 3, 5],
      },

      // Basic Bot 2 preferences - keeping dumb for default
      card_cost_value_reduction_by_age: [0, 0, 0],
      conserve_military_factor_by_age: [0, 0, 0],
      bid_nothing_threshold_by_age: [0, 0, 0],
      bid_nothing_probability_by_age: [0, 0, 0],
    };
  }

  getPreferences(): Preferences {
    return this.preferences_;
  }

  setPreferences(preferences: Preferences) {
    this.preferences_ = preferences;
  }

  computeBid(game_state: InflatedGame, user_id: string) {
    const player = this.getPlayer(game_state, user_id);
    const context = Rules.makeContextFromGame(player, game_state);

    let purchaseable_cards = affordableCards(context, game_state.table);

    // Note: this could be smarter to avoid giving away clash tributes, denying players etc
    if (_.isEmpty(purchaseable_cards)) {
      this.debug_log(
        '<!> ' + user_id + ': cant afford anything, picking randomly',
      );
      let allIndices = game_state.table.map((_, index) => index);
      return {
        military: 0,
        tradeRowIndex: randomSelect(allIndices),
      };
    }

    let best_card_by_playerID = this.bestCardToSelectByPlayerId(game_state);

    // this.debug_log( best_card_by_playerID[user_id]);
    let card_to_draft = best_card_by_playerID[user_id];
    let [card, remaining_cost, value] = card_to_draft;

    return {
      military: player.counters.military, // TODO, make informed decision about military bid
      tradeRowIndex: game_state.table.indexOf(card),
    };
  }

  // [(card, cost, value, bid)][]
  cardValues(
    game_state: InflatedGame,
    user_id: string,
    cards: Rules.CardWithID[],
  ): [Rules.CardWithID, number, number, number][] {
    const players = game_state.players;
    const player = this.getPlayer(game_state, user_id);
    const preferences = this.getPreferences();
    const context = Rules.makeContextFromGame(player, game_state);
    const player_total_counters = addCounters(
      player.counters,
      Rules.computeBaseCounters(context),
    );
    const total_output_by_userID: Record<string, CounterDelta> = {};
    const player_card_type_counts = AIUtilities.cardCompositionCounts(player);

    players.forEach((player) => {
      const context = Rules.makeContextFromGame(player, game_state);
      total_output_by_userID[player.userID] = addCounters(
        totalProductionCounters(context),
        Rules.computeBaseCounters(context),
      );
    });

    const max_opponent_military_output = _.max(
      _.map(players, (opponent) => {
        if (opponent.userID != user_id) {
          return total_output_by_userID[opponent.userID].military;
        }
      }) as number[],
    );
    const target_military_output =
      max_opponent_military_output + preferences.military_supremacy_target;

    let card_value_tuples: [Rules.CardWithID, number, number, number][] = _.map(
      cards,
      (card) => {
        let value = this.favorFromGettingCard(game_state, player, card, 0); // TODO determine bid
        this.debug_log(
          player.userID +
            ': [' +
            card.name +
            '] yields favor ' +
            value +
            ' for acquiring',
        );
        value *=
          preferences.card_flat_favor_preference_by_age[game_state.age - 1];

        value *= preferences.card_type_preferences[card.type];

        // Factor in the cost of the card
        if (player_total_counters.gold < card.cost) {
          value -= this.CANT_AFFORD_VALUE_DEDUCTION;
          this.debug_log(
            player.userID +
              ': [' +
              card.name +
              '] cant afford card (' +
              player.counters.gold +
              ' < ' +
              card.cost +
              ') reducing value by ' +
              this.CANT_AFFORD_VALUE_DEDUCTION,
          );
        }

        const cost_deduction =
          preferences.card_cost_value_reduction_by_age[game_state.age - 1] *
          card.cost;
        value -= cost_deduction;
        this.debug_log(
          player.userID +
            ': [' +
            card.name +
            ']  cost deduction to value ' +
            cost_deduction,
        );

        value += Math.random() * preferences.chaos;
        // Factor in the outputs of the card
        // TODO: consider moving to helper function
        // TODO: weight base and production outputs based on turn/age and player current base + production
        let value_from_outputs = 0;

        let card_total_gold_output = 0,
          card_total_military_output = 0;

        const card_base_outputs =
          CardAbilities.getBaseCountersAbility(card)?.getBaseCounters(context);
        let gold_base_output = card_base_outputs?.gold ?? 0;
        value_from_outputs +=
          gold_base_output *
          preferences.card_output_values_by_age['gold'][game_state.age - 1];
        card_total_gold_output += gold_base_output;

        let military_base_output = card_base_outputs?.military ?? 0;
        value_from_outputs +=
          military_base_output *
          preferences.card_output_values_by_age['military'][game_state.age - 1];
        card_total_military_output += military_base_output;

        const card_production_outputs =
          CardAbilities.getEachTurnCardAbility(card)?.onTurn(context);
        let gold_production_output = card_production_outputs?.gold ?? 0;
        value_from_outputs +=
          gold_production_output *
          preferences.card_output_values_by_age['gold'][game_state.age - 1];
        card_total_gold_output += gold_production_output;

        let military_production_output = card_production_outputs?.military ?? 0;
        value_from_outputs +=
          military_production_output *
          preferences.card_output_values_by_age['military'][game_state.age - 1];
        card_total_military_output += military_production_output;

        // add value for reaching target gold output
        if (
          total_output_by_userID[player.userID].gold <
            preferences.target_gold_output_by_age[game_state.age - 1] &&
          card_total_gold_output > 0
        ) {
          let value_add_for_target_gold =
            card_total_gold_output *
            (preferences.target_gold_output_by_age[game_state.age - 1] -
              total_output_by_userID[user_id].gold);
          this.debug_log(
            player.userID +
              ': [' +
              card.name +
              '] value for approaching target gold output ' +
              value_add_for_target_gold,
          );
          value += value_add_for_target_gold;
        }

        // add value for reaching target military output
        if (
          total_output_by_userID[player.userID].military <
            target_military_output &&
          card_total_military_output > 0
        ) {
          let value_add_for_target_military =
            card_total_military_output *
            this.preferences_.military_supremacy_preference *
            (target_military_output - total_output_by_userID[user_id].military);
          this.debug_log(
            player.userID +
              ': [' +
              card.name +
              '] value for approaching target military output ' +
              value_add_for_target_military,
          );
          value += value_add_for_target_military;
        }

        const card_ongain_outputs =
          CardAbilities.getGainSelfCardAbility(card)?.onGainSelf(context);
        value_from_outputs +=
          (card_ongain_outputs?.gold ?? 0) *
          preferences.card_output_values_by_age['gold'][game_state.age - 1];
        value_from_outputs +=
          (card_ongain_outputs?.military ?? 0) *
          preferences.card_output_values_by_age['military'][game_state.age - 1];

        value += value_from_outputs;
        this.debug_log(
          player.userID +
            ': [' +
            card.name +
            '] value from outputs ' +
            value_from_outputs,
        );

        const card_type = card.type;
        if (
          player_card_type_counts[card_type] <
          preferences.target_card_type_by_age[card_type][game_state.age - 1]
        ) {
          let value_add_for_target_card_type =
            preferences.target_card_type_by_age[card_type][game_state.age - 1] -
            player_card_type_counts[card_type];
          this.debug_log(
            player.userID +
              ': [' +
              card.name +
              '] value for approaching target card type count ' +
              value_add_for_target_card_type,
          );
          value += value_add_for_target_card_type;
        }

        let cost = card.cost;
        return [card, cost, value, player.counters.military];
      },
    );

    card_value_tuples = _.sortBy(
      card_value_tuples,
      ([card, cost, value]) => -value,
    ); //- for desc
    this.debug_log(user_id + ': card_value_tuples: ');
    // @ts-ignore
    this.debug_log(
      _.map(card_value_tuples, ([card, cost, value]) => {
        return [value, card.name];
      }),
    );

    return card_value_tuples;
  }

  override computeResolutionSelection(
    game_state: InflatedGame,
    user_id: string,
  ): Rules.ResolutionSelection {
    const player = this.getPlayer(game_state, user_id);
    const context = Rules.makeContextFromGame(player, game_state);
    let selectedCardID = player.selectedCard?.id;
    let affordableBasicCards = game_state.basicPile.filter(
      (card) => Rules.canAffordCard(context, card).canAfford,
    );
    const card_descending_values = this.cardValues(
      game_state,
      user_id,
      affordableBasicCards,
    );
    let basicCardID = card_descending_values[0][0].id;

    let isAffordable =
      selectedCardID &&
      Rules.canAffordCard(context, game_state.cardsByID[selectedCardID])
        .canAfford;

    return {
      cardIDToGain:
        isAffordable && selectedCardID ? selectedCardID : basicCardID,
    };
  }

  favorFromGettingCard(
    game_state: InflatedGame,
    player: InflatedPlayer,
    card: Rules.CardWithID,
    bid_military: number,
  ) {
    player = { ...player };
    let context = Rules.makeContextFromGame(player, game_state);
    if (!player.bid) {
      player.bid = {
        military: bid_military,
        tradeRowIndex: game_state.table.findIndex((x) => x.id === card.id),
      };
    }
    context = Rules.handleBid(context, {
      military: player.counters.military, // TODO factor military into logic
      tradeRowIndex: game_state.table.indexOf(card),
    });
    context = Rules.handleGainCard(context, card);
    context = Rules.handleResolutionSelection(
      context,
      card,
      card, // NOTE: assumes gaining same card drafted
    );
    context = this.handleTributes(context, game_state, player, card);

    return context.production_delta.favor;
  }

  handleTributes(
    context: Rules.Context,
    game_state: InflatedGame,
    player: InflatedPlayer,
    selectedCard: Rules.CardWithID,
  ) {
    let tributes = game_state.tributeRow;

    let planning_data = {} as Record<string, any>;
    let resolution_data = {} as Record<string, any>;

    _.each(tributes, (tribute) => {
      var tributeLogic = TributeLogic.getTributeLogic(tribute);
      planning_data[tribute.id] =
        tributeLogic &&
        tributeLogic.planningDataFunction &&
        // @ts-ignore
        tributeLogic.planningDataFunction(context, [], game_state.players);
    });

    _.each(tributes, (tribute) => {
      var tributeLogic = TributeLogic.getTributeLogic(tribute);
      resolution_data[tribute.id] =
        tributeLogic &&
        tributeLogic.resolutionDataFunction &&
        tributeLogic.resolutionDataFunction(
          context,
          game_state.table,
          selectedCard,
        );
    });

    var completed_tribute_context = Rules.handleTributeGain(
      context,
      tributes,
      planning_data,
      resolution_data,
      {}, // tribute production
      game_state.players,
      null, // conflict results
    );

    return completed_tribute_context;
  }

  bestCardToSelectByPlayerId(game_state: InflatedGame) {
    let players = game_state.players;
    // jumble players array to throw off bots ordering logic
    players = _.shuffle(players);
    // card, cost, value, bid
    let card_descending_values_by_playerID: {
      [user_id: string]: [Rules.CardWithID, number, number, number][];
    } = {};
    let totalcounters_by_playerID: {
      [user_id: string]: CounterDelta;
    } = {};
    let card_index_by_playerID: {
      [user_id: string]: number;
    } = {};
    let max_opponent_military_counters_by_playerID: {
      [user_id: string]: number;
    } = {};
    // card, cost, value, bid
    const CARD = 0,
      COST = 1,
      VALUE = 2,
      BID = 3;
    let best_card_values_by_playerID: {
      [user_id: string]: [Rules.CardWithID, number, number, number];
    } = {};

    // setup for each players
    players.forEach((player) => {
      let user_id = player.userID;
      const context = Rules.makeContextFromGame(player, game_state);
      totalcounters_by_playerID[user_id] = addCounters(
        player.counters,
        Rules.computeBaseCounters(context),
      );
      card_descending_values_by_playerID[user_id] = this.cardValues(
        game_state,
        user_id,
        game_state.table,
      );
      card_index_by_playerID[user_id] = 0;
      best_card_values_by_playerID[user_id] =
        card_descending_values_by_playerID[user_id][
          card_index_by_playerID[user_id]
        ];
    });

    // find max opponent military counters
    players.forEach((player) => {
      let user_id = player.userID;
      max_opponent_military_counters_by_playerID[user_id] = _.max(
        _.map(players, (opponent) => {
          if (opponent.userID != user_id) {
            return totalcounters_by_playerID[opponent.userID]?.military || 0;
          }
        }) as number[],
      );
    });

    // iterate for each player to settle on a selection and bid until there are no changes
    // TODO: consider also resetting if bid changes, although this could cause infinite loop
    let index_changed = true;
    while (index_changed) {
      index_changed = false;
      this.debug_log(
        '--- calculating all players --- age' +
          game_state.age +
          'turn ' +
          game_state.turn +
          '---',
      );
      players.forEach((player) => {
        let user_id = player.userID;
        this.debug_log('- ' + user_id + ' -');
        let military = totalcounters_by_playerID[user_id].military;
        let desired_card_index = card_index_by_playerID[user_id];
        let [desired_card, _cost, _value] =
          best_card_values_by_playerID[user_id];

        players.forEach((opponent) => {
          let opponent_id = opponent.userID;
          if (opponent_id == user_id) {
            return;
          }

          let opponent_military =
            totalcounters_by_playerID[opponent_id].military;
          let [opponent_desired_card, _cost, _value] =
            best_card_values_by_playerID[opponent_id];

          let next_card = null as Rules.CardWithID | null,
            next_card_cost,
            next_card_value = 0;
          if (desired_card_index < game_state.table.length - 1) {
            [next_card, next_card_cost, next_card_value] =
              card_descending_values_by_playerID[user_id][
                desired_card_index + 1
              ];
          }

          // if there is a presumed conflict, consider the probability of winning
          if (opponent_desired_card.id === desired_card.id) {
            // need special logic for when no card is safe to draft?
            const prob_of_winning = probabilityWinningMultiBattle(
              game_state.age,
              military,
              [[game_state.age, opponent_military]],
            );
            this.debug_log(
              user_id +
                ': [' +
                desired_card.name +
                '] also desired by ' +
                opponent_id +
                '. their military ' +
                opponent_military +
                ' vs our ' +
                military +
                ' (' +
                prob_of_winning +
                '%)',
            );

            let pick_next_card = false;
            let new_value = 0;
            if (next_card && next_card_value > 0) {
              // if not other options, stick with card; TODO: still consider different bids) {
              new_value = prob_of_winning * _value;
              this.debug_log(
                user_id +
                  ': [' +
                  desired_card.name +
                  '] value reduced to ' +
                  new_value +
                  ' due to conflict. next card [' +
                  next_card.name +
                  '] value ' +
                  next_card_value,
              );

              // if next card is now more valuable, pick it
              if (new_value < next_card_value) {
                this.debug_log(
                  user_id + ': picking next card [' + next_card.name + ']',
                );
                desired_card_index++;
                card_index_by_playerID[user_id] = desired_card_index;
                best_card_values_by_playerID[user_id] =
                  card_descending_values_by_playerID[user_id][
                    desired_card_index
                  ];
                pick_next_card = true;
                index_changed = true;
                // continue/break?
              }
            }

            if (!pick_next_card) {
              let to_bid = min([
                military,
                opponent_military + 5 * game_state.age,
              ]);
              // if (
              //   this.preferences_.conserve_military_factor_by_age[
              //     game_state.age - 1
              //   ] > 0
              // ) {
              //   to_bid -=
              //     (new_value - next_card_value) *
              //     this.preferences_.conserve_military_factor_by_age[
              //       game_state.age - 1
              //     ];
              // }
              // if sticking with same card, only outbid to 100%
              best_card_values_by_playerID[user_id][BID] = to_bid;
              card_index_by_playerID[user_id] = desired_card_index;
              best_card_values_by_playerID[user_id] =
                card_descending_values_by_playerID[user_id][desired_card_index];
              this.debug_log(
                user_id +
                  ': sticking with [' +
                  desired_card.name +
                  '] bidding ' +
                  best_card_values_by_playerID[user_id][BID],
              );
            }
          } else {
            //TODO: if no presumed conflict, only bid based on EV of card
            let to_bid = min([
              military,
              opponent_military + 5 * game_state.age,
            ]);
            // random change to bid nothing
            if (
              // value less than threshold
              _value <
                this.preferences_.bid_nothing_threshold_by_age[
                  game_state.age - 1
                ] &&
              Math.random() <
                this.preferences_.bid_nothing_probability_by_age[
                  game_state.age - 1
                ]
            ) {
              to_bid = 0;
              this.debug_log(user_id + ': bidding nothing ****');
            }

            best_card_values_by_playerID[user_id][BID] = to_bid;
            this.debug_log(
              user_id +
                ': picking [' +
                desired_card.name +
                '] no conflict, bidding ' +
                best_card_values_by_playerID[user_id][BID] +
                ' (opponent desired card: [' +
                opponent_desired_card.name +
                '])',
            );
          }
        });
      });
    }

    return best_card_values_by_playerID;
  }

  debug_log(...content: any[]) {
    if (this.verbose) {
      console.log(content);
    }
  }
}
