import * as _ from 'underscore';
import invariant from 'invariant';

import Phases from './Phases';
import * as Rules from './Rules';

import Player from './Player';
import { InflatedGame, InflatedPlayer } from './Game';
import nullthrows from 'nullthrows';

import { ActionTypes, GameAction } from './GameActions';

export default class Bot {
  constructor() {}

  actionForGameState(
    game_state: InflatedGame,
    user_id: string,
  ): GameAction | null {
    invariant(
      _.find(game_state.players, (player) => player.userID === user_id),
      'bot not in game',
    );

    if (game_state.gameEndTimestamp) {
      return null;
    }

    if (game_state.readyByUserID[user_id]) {
      return null;
    }

    if (game_state.phase === Phases.PLANNING) {
      return {
        type: ActionTypes.BID,
        payload: {
          bid: this.computeBid(game_state, user_id),
        },
      };
    } else if (game_state.phase === Phases.WAR) {
      const bid = this.getPlayer(game_state, user_id).bid;
      invariant(bid, 'bot must have a bid at the War phase');
      return {
        type: ActionTypes.REROLL,
        payload: {
          rollSelection: {
            rerollType: 'decline',
            rerollRound: game_state.rerollRound,
          },
        },
      };
    } else if (game_state.phase === Phases.RESOLUTION) {
      return {
        type: ActionTypes.RESOLVE,
        payload: {
          resolve: this.computeResolutionSelection(game_state, user_id),
        },
      };
    } else {
      invariant(false, 'unknown phase %s', game_state.phase);
    }
  }

  // helpers

  getPlayer(game_state: InflatedGame, user_id: string): InflatedPlayer {
    return nullthrows(
      game_state.players.find((player) => player.userID === user_id),
    );
  }

  getContext(game_state: InflatedGame, player: Player): Rules.Context {
    return Rules.makeContext(
      player,
      game_state.turn,
      game_state.age,
      game_state.options,
    );
  }

  // Subclasses begin overriding here
  computeBid(game_state: InflatedGame, user_id: string): Rules.Bid {
    return {
      military: 0,
      tradeRowIndex: 0,
    };
  }

  computeResolutionSelection(
    game_state: InflatedGame,
    user_id: string,
  ): Rules.ResolutionSelection {
    return {
      cardIDToGain: game_state.basicPile[0].id,
    };
  }
}
