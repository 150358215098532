import _ from 'underscore';

import Phases from '../game/Phases';
import * as Rules from '../game/Rules';
import * as TributeLogic from '../game/TributeLogic';

import GameMutator from './GameMutator';
import { InflatedGame, InflatedPlayer } from '../game/Game';
import nullthrows from 'nullthrows';
import { findReverse } from '../utils/utils';
import { EventTypes, GameEventOfType } from '../game/GameEvents';
import invariant from 'invariant';
import { ActionTypes, GameAction } from '../game/GameActions';

type ActionStoreListener = () => void;

export type ActionStoreCanSubmitResult = {
  result: boolean;
  message?: string;
};
type WarInfo = {
  rerollType: Rules.RerollType;
  rerollRound: number;
};
export default class ActionStore {
  private userID_: string;
  private game_: InflatedGame;
  private listeners_: ActionStoreListener[] = [];

  private _selectedCard: Rules.CardWithID | null = null;
  private _military: number = 0;
  private _warInfo: WarInfo | null = null;

  constructor(game: InflatedGame, userID: string) {
    this.userID_ = userID;
    this.game_ = game;
    console.log('action store constructor game', game);

    this.clearSelection();
    this.updateSelectionsFromGame_();
  }

  addListener(callback: ActionStoreListener) {
    this.listeners_.push(callback);
  }
  removeListener(callback: ActionStoreListener) {
    this.listeners_ = _.without(this.listeners_, callback);
  }

  setGameIfNecessary(game: InflatedGame) {
    if (!game) {
      return;
    }
    if (!this.game_ || this.game_.sequenceID < game.sequenceID) {
      this.setGame(game);
    }
  }

  setGame(game: InflatedGame) {
    const old_phase = this.game_.phase;
    this.game_ = game;

    console.log('action store set game', game.phase);
    if (game.phase === Phases.WAR) {
      console.log('war phase');
      this.updateSelectionsFromGame_();
    } else {
      this._warInfo = null;
    }

    if (old_phase != game.phase) {
      const selectedCardID = this.getPlayer().selectedCardID;
      if (game.phase === Phases.PLANNING) {
        this._selectedCard = null;
        this._military = 0;
      } else if (game.phase === Phases.RESOLUTION && selectedCardID) {
        this.updateSelectionsFromGame_();
        var selected_card = _.find(
          this.game_.table,
          (card) => card.id === selectedCardID,
        );
        if (!selected_card) {
          this.clearSelection();
          return;
        }
        this._selectedCard = selected_card;
      } else {
        this.clearSelection();
      }
      this.onSelectionsChanged_();
    } else if (this._selectedCard) {
      this._selectedCard = nullthrows(
        this.game_.cardsByID[this._selectedCard.id],
      ) as Rules.CardWithID;
    }
  }

  updateSelectionsFromGame_() {
    let game = this.game_;
    let player = this.getPlayer();

    let changed = false;
    if (player.bid) {
      this._military = player.bid.military;
      this._selectedCard = game.table[
        player.bid.tradeRowIndex
      ] as Rules.CardWithID;
      changed = true;
    }

    if (
      this.game_.phase === Phases.WAR &&
      this.game_.rerollRound !== this._warInfo?.rerollRound
    ) {
      this._warInfo = {
        rerollType: 'na',
        rerollRound: game.rerollRound,
      };
      changed = true;
    }

    if (changed) {
      this.onSelectionsChanged_();
    }
  }

  clearSelection() {
    this._selectedCard = null;
  }

  getSelectedCardID(): string | null {
    return this._selectedCard ? this._selectedCard.id : null;
  }

  getMilitaryBidOrNan(): number {
    return this._military;
  }
  getMilitaryBid(): number {
    return this._military || 0;
  }

  getWarInfo(): WarInfo | null {
    return this._warInfo;
  }

  canSubmit(): ActionStoreCanSubmitResult {
    var player = this.getPlayer();
    try {
      var game_info = {
        turn: this.game_.turn,
        phase: this.game_.phase,
        age: this.game_.age,
        basicPile: this.game_.basicPile,
        tradeRow: this.game_.table,
        cardsByID: this.game_.cardsByID,
        options: this.game_.options,
      };

      if (this.game_.phase === Phases.PLANNING) {
        if (!this._selectedCard) {
          return {
            result: false,
            message: 'You must select a card to draft',
          };
        }
        const tradeRowIndex = this.game_.table.findIndex(
          (x) => x.id === this._selectedCard!.id,
        );
        Rules.validateBid(
          player,
          { military: this.getMilitaryBid(), tradeRowIndex },
          game_info,
        );
      } else if (this.game_.phase === Phases.RESOLUTION) {
        if (!this._selectedCard) {
          return {
            result: false,
            message: 'You must select a card to gain',
          };
        }
        Rules.validateResolve(
          player,
          { cardIDToGain: this._selectedCard!.id },
          game_info,
        );
      } else if (this.game_.phase === Phases.WAR) {
        invariant(this._warInfo, 'must have war info');
        if (this._warInfo.rerollType === 'na') {
          return {
            result: false,
            message: 'You must make a choice.',
          };
        }
      }

      return {
        result: true,
      };
    } catch (e: any) {
      return {
        result: false,
        message: e.message,
      };
    }
  }

  getUnaffordableCards(): Map<string, string> {
    let cards: Rules.CardWithID[] = [];
    if (this.game_.phase === Phases.PLANNING) {
      cards = [...this.game_.table, ...this.game_.basicPile];
    } else if (this.game_.phase === Phases.RESOLUTION) {
      cards = [...this.game_.basicPile];
      const selectedCard = this.getPlayer().selectedCard;
      if (selectedCard) {
        cards.push(selectedCard);
      }
    }

    const ret = new Map<string, string>();
    const context = this.getContext();
    cards.forEach((card) => {
      const canAfford = Rules.canAffordCard(context, card);
      if (!canAfford.canAfford) {
        ret.set(card.id, canAfford.reason);
      }
    });
    return ret;
  }

  getCompletedTributeIDs(): string[] {
    // XXX: some tributes might not require a selection to be completed
    // this will prevent them from being optimistically highlighted before
    // a card is selected
    if (!this._selectedCard) {
      return [];
    }

    const player = {
      ...this.getPlayer(),
    };
    if (!player.bid) {
      player.bid = {
        military: this.getMilitaryBid(),
        tradeRowIndex: this.game_.table.findIndex(
          (x) => x.id === this._selectedCard!.id,
        ),
      };
    }
    let context = Rules.makeContextFromGame(player, this.game_);
    let tributes = this.game_.tributeRow;

    let planning_data: {
      [tributeID: string]: TributeLogic.TributeLogicPlanningData;
    } = {};
    let resolution_data: {
      [tributeID: string]: TributeLogic.TributeLogicResolutionData;
    } = {};

    if (this.game_.phase === Phases.PLANNING) {
      context = Rules.handleBid(context, player.bid);

      const roundResult = {
        winningPlayerID: player.userID,
        rounds: [
          {
            [player.userID]: {
              playerID: player.userID,
              total: 0,
            },
          },
        ],
        participantPlayerIDs: [player.userID],
      };
      tributes.forEach((tribute) => {
        const tributeLogic = TributeLogic.getTributeLogic(tribute);
        planning_data[tribute.id] = tributeLogic?.planningDataFunction(
          context,
          {
            results: roundResult,
            players: this.game_.players,
            table: this.game_.table,
          },
        );
      });
    } else {
      planning_data = this.game_.planningDataByPlayerID[player.userID] || {};
    }

    if (
      this.game_.phase === Phases.PLANNING ||
      this.game_.phase === Phases.RESOLUTION
    ) {
      context = Rules.handleResolutionSelection(
        context,
        this._selectedCard,
        this._selectedCard,
      );
      context = Rules.handleGainCard(context, this._selectedCard);
      player.cards = [...player.cards, this._selectedCard];

      player.counters = { ...player.counters };
      Rules.resolveContextCounters(context);

      tributes.forEach((tribute) => {
        const tributeLogic = TributeLogic.getTributeLogic(tribute);
        resolution_data[tribute.id] = tributeLogic?.resolutionDataFunction(
          context,
          this.game_.table,
          this._selectedCard!,
        );
      });
    }

    let completed_tribute_context = Rules.makeContextFromGame(
      player,
      this.game_,
    );
    const conflictResultsEvent =
      findReverse(this.game_.events, (event) => {
        return (
          event.age === this.game_.age &&
          event.turn === this.game_.turn &&
          event.type === EventTypes.CONFLICT_RESULTS &&
          event.payload.rounds[0][player.userID] != null
        );
      }) || null;
    completed_tribute_context = Rules.handleTributeGain(
      completed_tribute_context,
      tributes,
      planning_data,
      resolution_data,
      // TODO optimistic production?
      {},
      this.game_.players,
      conflictResultsEvent as GameEventOfType<EventTypes.CONFLICT_RESULTS> | null,
    );
    return completed_tribute_context.completedTributeIDs;
  }
  getDisallowedCardIDs(): Set<string> {
    if (this.game_.phase === Phases.RESOLUTION) {
      let disallowed = new Set(this.getUnaffordableCards().keys());

      this.game_.tableIDs.forEach((cardID) => {
        if (this.getPlayer().selectedCardID === cardID) {
          return;
        }
        disallowed.add(cardID);
      });
      return disallowed;
    }
    return new Set();
  }
  canAffordCard(card: Rules.CardWithID): Rules.CanAffordCardResult {
    return Rules.canAffordCard(this.getContext(), card);
  }

  getSubmitWarning(): string | null {
    if (this.game_.phase === Phases.PLANNING) {
      const gainCard = this._selectedCard;
      if (!gainCard) {
        return 'You must select a card to draft';
      }

      const canAfford = this.canAffordCard(gainCard);
      if (!canAfford.canAfford) {
        const canAffordAny = this.game_.table.some(
          (card) => this.canAffordCard(card).canAfford,
        );
        if (canAffordAny) {
          return canAfford.reason;
        }
      }
    }

    return null;
  }
  didClickSubmit(): void {
    if (!this.canSubmit().result) {
      return;
    }

    let warning = this.getSubmitWarning();
    if (warning) {
      if (!confirm(warning)) {
        return;
      }
    }

    const phase = this.game_.phase;
    if (phase === Phases.PLANNING) {
      this.sendAction_({
        type: ActionTypes.BID,
        payload: {
          bid: {
            military: this.getMilitaryBid(),
            tradeRowIndex: this.game_.table.findIndex(
              (x) => x.id === this._selectedCard!.id,
            ),
          },
        },
      });
    } else if (phase === Phases.WAR) {
      const bid = this.getPlayer().bid;
      invariant(bid, 'player must have a bid at the War phase');
      invariant(this._warInfo, 'must have war info');
      this.sendAction_({
        type: ActionTypes.REROLL,
        payload: {
          rollSelection: {
            rerollType: this._warInfo.rerollType,
            rerollRound: this.game_.rerollRound,
          },
        },
      });
    } else if (phase === Phases.RESOLUTION) {
      this.sendAction_({
        type: ActionTypes.RESOLVE,
        payload: {
          resolve: {
            cardIDToGain: this._selectedCard!.id,
          },
        },
      });
    } else {
      console.error('unknown phase', phase);
    }
  }

  didClickDeclineReroll(): void {
    invariant(this.game_.phase === Phases.WAR, 'must be at War phase');
    invariant(this._warInfo, 'must have war info');

    this._warInfo.rerollType = 'decline';
    this.notifyListeners_();
  }
  didClickSubmitReroll(): void {
    invariant(this.game_.phase === Phases.WAR, 'must be at War phase');
    invariant(this._warInfo, 'must have war info');
    this._warInfo.rerollType = 'reroll';
    this.notifyListeners_();
  }
  didChangeMilitary(military: number): void {
    if (this.isSessionPlayerReady()) {
      return;
    }
    if (this.game_.phase !== Phases.PLANNING) {
      return;
    }
    this._military = Math.max(
      Math.min(military, this.getPlayer().counters.military),
      0,
    );

    this.notifyListeners_();
  }
  didClickDraftRowCard(card: Rules.CardWithID): void {
    if (this.isSessionPlayerReady()) {
      return;
    }
    var phase = this.game_.phase;
    if (phase === Phases.PLANNING) {
      this.selectPlanningCard_(card);
    } else if (phase === Phases.RESOLUTION) {
      this.selectResolutionCard_(card);
    } else {
      console.log('no selections for this phase');
    }
  }
  didClickBasicCard(card: Rules.CardWithID): void {
    if (this.isSessionPlayerReady()) {
      return;
    }
    var phase = this.game_.phase;
    if (phase === Phases.RESOLUTION) {
      this.selectResolutionCard_(card);
    }
  }

  getPlayer(): InflatedPlayer {
    return nullthrows(
      this.game_.players.find((player) => player.userID === this.userID_),
    );
  }
  getContext(): Rules.Context {
    return Rules.makeContextFromGame(this.getPlayer(), this.game_);
  }

  isSessionPlayerReady(): boolean {
    return this.game_.readyByUserID[this.getPlayer().userID];
  }

  // shouldShowToggleButton(): boolean {
  //   return (
  //     !this.isSessionPlayerReady() &&
  //     this.game_.phase === Phases.PLANNING &&
  //     this.game_.options.enableToggleButton
  //   );
  // }

  // Implementation Details
  notifyListeners_(): void {
    this.listeners_.forEach((l) => l());
  }
  onSelectionsChanged_(): void {
    this.notifyListeners_();
  }
  selectPlanningCard_(card: Rules.CardWithID): void {
    this._selectedCard = card;
    this.onSelectionsChanged_();
  }
  selectResolutionCard_(card: Rules.CardWithID): void {
    var player = this.getPlayer();

    if (!this.canAffordCard(card).canAfford) {
      return;
    }

    const isSelection = card.id === player.selectedCardID;
    const isBasic = this.game_.basicPile.some((basic) => basic.id === card.id);
    if (!(isSelection || isBasic)) {
      return;
    }

    this._selectedCard = card;
    this.onSelectionsChanged_();
  }
  sendAction_(action: GameAction) {
    if (!this.game_) {
      console.log('no game to send action!');
      return;
    }
    GameMutator.sendAction(this.game_.id, action);
  }
}
